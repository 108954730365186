<template>
  <div class="login-page">
    <div @click="pageBack" class="close-btn flex-center">
      <van-icon name="cross" size="16" color="#000000"/>
    </div>
    <div class="login-container">
      <div class="logo">
        <img src="~@/assets/images/logo.png" width="100%" alt="">
      </div>
      <div class="login-form">
        <div class="form-item">
          <!-- <div class="form-label">+86</div> -->
          <div class="form-input">
            <input type="text" placeholder="请输入手机号" v-model="phone" maxlength="11">
          </div>
        </div>
        <div class="form-item">
          <div class="form-input">
            <input type="text" placeholder="请输入短信验证码" v-model="captcha" maxlength="6">
            <div class="captcha" @click="getCaptcha">
              <span>{{ isCountDown ? '重新获取(' + count + 's)' : '获取验证码' }}</span>
            </div>
          </div>
        </div>
        <van-button type="info" class="form-btn flex-center" 
          :class="{ 'disabled' : (phone == '' || captcha == '')}"
          :loading="loading"
          @click="onSubmit">
          <span>登录</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Icon, Toast, Notify, Button } from 'vant'
  import { isPhoneNumber } from '@/utils/common'
  import { getPhoneCaptcha, bindPhoneNumber } from '@/api/user'
  import Cookies from 'js-cookie'
  import Store from 'store'

  export default {
    components: {
      [Icon.name]: Icon,
      [Button.name]: Button,
    },
    data() {
      return {
        phone: '',
        captcha: '',
        isCountDown: false,
        count: 60,
        timer: null,
        captchaLoading: false,
        loading: false,
        redirect: ''
      }
    },
    created() {
      this.redirect = this.$route.query && this.$route.query.redirect
    },
    methods: {
      pageBack() {
        if (window.history.length <= 1) {
          this.$router.push({path:'/'})
          return false
        } else {
          this.$router.go(-1)
        }
      },
      getCaptcha() {
        if (this.isCountDown === true || this.captchaLoading === true) {
          return
        }
        if (this.phone == '') {
          Notify('请输入手机号')
          return
        }
        if (!isPhoneNumber(this.phone)) {
          Notify('手机号格式不正确')
          return
        }
        this.captchaLoading = true
        getPhoneCaptcha({mobile: this.phone}).then(() => {
          Toast('验证码发送成功')
          this.count = 60
          this.isCountDown = true
          this.captchaLoading = false
          if (this.timer) {
            clearInterval(this.timer)
          }
          this.timer = setInterval(() => {
            if (this.count === 0) {
              clearInterval(this.timer)
              this.isCountDown = false
            }
            this.count --
          }, 1000)
        }).catch(() => {
          this.captchaLoading = false
        })
        // 发送验证码
      },
      onSubmit() {
        if (this.loading === true) {
          return
        }
        if (this.phone == '') {
          Notify('请输入手机号')
          return
        }
        if (!isPhoneNumber(this.phone)) {
          Notify('手机号格式不正确')
          return
        }
        if (this.captcha == '') {
          Notify('请输入短信验证码')
          return
        }
        this.loading = true
        // 登录成功
        const params = {
          mobile: this.phone,
          code: this.captcha
        }
        params.unionid = Cookies.get('user_unionid_v3') || Store.get('wx_user_v3').unionid
        bindPhoneNumber(params).then(res => {
          const { userinfo } = res.data
          Store.set('wx_user_v3', userinfo)
          Cookies.set('user_unionid_v3', userinfo.unionid)
          Cookies.set('user_mobile', userinfo.mobile)
          Cookies.set('hasLogin_v2', 'true')
          this.$router.replace({ path: this.redirect || '/' })
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
        // if (Store.get('wx_user_v3') && isWXBrowser()) {
          
        // } else {
        //   onLogin(params).then(res => {
        //     const { userinfo } = res.data
        //     Cookies.set('user_unionid_v3', userinfo.unionid)
        //     Cookies.set('user_mobile', userinfo.mobile)
        //     Cookies.set('hasLogin_v2', true)
        //     this.$router.replace({ path: this.redirect || '/' })
        //     this.loading = false
        //   }).catch(() => {
        //     this.loading = false
        //   })
        // }
        
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>